import React from 'react';

import { ReactComponent as Bot } from '../../assets/icons/bot-wave.svg';
import { ReactComponent as Top } from '../../assets/icons/top-wave.svg';
import image from '../../assets/images/bread-home.png';
import { ScContainer } from '../../components/container/styled';
import { useMatchMedia } from '../../hooks/use-match-media';

import {
	ScAboutUsHeroContainer,
	ScAboutUsHeroContent,
	ScAboutUsHeroPeach,
	ScAboutUsHeroTextColumns,
	ScAboutUsHeroWrapper,
} from './styled';

export const AboutUsHero = () => {
	const isDesktop = useMatchMedia(`(min-width: 1220px)`);

	return (
		<ScAboutUsHeroContainer>
			<Top />
			<ScAboutUsHeroWrapper>
				<ScContainer>
					<ScAboutUsHeroContent>
						<h3>About us</h3>
						<p>
							We only offer salary sacrifice benefits that can make a tangible difference to
							people’s lives and we make them as easy as possible for employers to deliver.
						</p>
						<ScAboutUsHeroPeach>
							<img src={image} alt="bread-hero" />
							<h3>
								same dough
								<br />
								more bread
							</h3>
						</ScAboutUsHeroPeach>
						<ScAboutUsHeroTextColumns>
							<div>
								<p>
									That’s because we believe it shouldn’t be hard to deliver great benefits for
									employees.
								</p>
								<p>
									For HR & People teams this means everything about Gogeta is designed to be simple,
									effortless and intuitive. We automate wherever we can, enable employees to
									self-serve as much as possible, yet are always on-hand if you want to speak to a
									human.{' '}
									{!isDesktop &&
										'For employees, this means we offer the biggest savings and the biggest choice, with no hidden fees or surcharges.'}
								</p>
							</div>
							<div>
								{isDesktop && (
									<p>
										For employees, this means we offer the biggest savings and the biggest choice,
										with no hidden fees or surcharges.
									</p>
								)}
								<p>
									For our partners (bike retailers and nurseries) this means we offer the fairest
									deal. They should benefit when people use our schemes. We’re proud to be a BCorp
									business, in recognition of our fair and transparent way of doing business and our
									positive social impact.
								</p>
							</div>
						</ScAboutUsHeroTextColumns>
					</ScAboutUsHeroContent>
				</ScContainer>
			</ScAboutUsHeroWrapper>
			<Bot />
		</ScAboutUsHeroContainer>
	);
};
