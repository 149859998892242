import React from 'react';

import { ScPage } from '../components/page/styled';
import { MainLayout } from '../layouts/main-layout';
import { AboutUsInfoSection } from '../sections/AboutUs/AboutUsInfoSection';
import { AboutUsHero } from '../sections/AboutUs/Hero';
import { AboutUsReadyToStartSaving } from '../sections/AboutUs/ReadyToStartSaving';

const AboutUs = () => {
	return (
		<MainLayout
			title="Gogeta | Fair, Transparent, and Effortless Salary Sacrifice Benefits"
			description="Gogeta offers simple, low admin salary sacrifice benefits with big savings for employees and fair deals for partners. Proud BCorp with a positive social impact."
		>
			<ScPage>
				<AboutUsHero />
				<AboutUsInfoSection />
				<AboutUsReadyToStartSaving />
			</ScPage>
		</MainLayout>
	);
};

export default AboutUs;
